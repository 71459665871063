import React, { useState } from "react"
import { request } from '../../helpers/axios_helper';

export function TaskToday(props) {
    const player = props.player;

    console.log(player)
    
    const getTasks = () => {
        request (
            "GET",
            `/api/v1/tasks/${player.id}/today`,
            {}
        ).then((response) => {
            setTodaysTasks(response.data);
        }).catch((error) => {
            if (error.response.status === 401) {
            navigate('/');
            }
        });
    }

    const [ todaysTasks, setTodaysTasks ] = useState(() => getTasks()) 

    console.log(todaysTasks);
    return (
        <div>
            <h3>Today's tasks</h3>
            <ul className="list-group">
            {   
                todaysTasks && todaysTasks.map(task => 
                    <li className="list-group-item" id={task.id} key={task.id}>{task.name}</li>
                )
            }
            </ul>
        </div>
    )
}
