import React, { useState } from 'react'
import { request } from '../../helpers/axios_helper'
import { Button } from 'react-bootstrap'

export function TaskForm () {
    const [ taskName, setTaskName ] = useState('')
    const [ taskDate, setTaskDate ] = useState('')
    const [ taskWeekDays, setTaskWeekDays ] = useState([])
    const [ taskType, setTaskType ] = useState('')
    const [ taskImportance, setTaskImportance ] = useState(1)
    const [ taskEffort, setTaskEffort ] = useState(1)

    const [ taskError, setTaskError ] = useState('')

    const onPlayerCreation = () => {
        request (
            "POST",
            "/api/v1/tasks",
            {
                name: taskName, 
                date: taskDate,
                weekDays: taskWeekDays,
                occurrenceType: taskType,
                importance: taskImportance,
                effort: taskEffort,
                score: taskScore
            }
        ).then((response) => {
            if (response.status == 201) {
              navigate("/tasks");      
            }
        }).catch((error) => {
          if (error.response.status === 401) {
            setUser(null);
            navigate('/');
          }
        });
      }

    return (
    <div className='container'>
        <form>
            <div className='row'>
              <h2 className='text-center'>New Task</h2>
              <div className='row'>
                  <label htmlFor='name' className='px-1 pt-2' style={{textAlign: 'left'}}>Name</label>
                  <input type='text' className='form-control rounded-3' id="name" name="name" value={taskName} placeholder='Type a name' onChange={ev => setTaskName(ev.target.value)}/>
              </div>
            </div>

            <div className='row'>
              <label htmlFor='date' className='px-1 pt-2' style={{textAlign: 'left'}}>Date</label>
              <input type='text' className='form-control rounded-3' id="date" name="date" value={taskDate} placeholder='Select the date of birth' onChange={ev => setTaskDate(ev.target.value)}/>
            </div>

            <div className='row'>
              <label htmlFor='type' className='px-1 pt-2' style={{textAlign: 'left'}}>Type</label>
              <select class="form-select" aria-label="Default select example" id="type" name="type" value={taskDate} placeholder='Select the task type' onChange={ev => setTaskDate(ev.target.value)}>
                <option selected value="onetime">One Time</option>
                <option value="daily">Daily</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
              </select>
            </div>

            <div className='row'>
                <label className='px-1 pt-2' style={{textAlign: 'left'}}>Week days</label>
            </div>
            <div className='row'>
                <div class="btn-toolbar" role="toolbar">
                    <input type="checkbox" className="btn-check" id="btn-check-sun" autocomplete="off"/>
                    <label class="btn btn-outline-primary mx-1" for="btn-check-sun">S</label><br></br>

                    <input type="checkbox" className="btn-check" id="btn-check-mon" autocomplete="off"/>
                    <label class="btn btn-outline-primary mx-1" for="btn-check-mon">M</label><br></br>
                        
                    <input type="checkbox" className="btn-check" id="btn-check-tue" autocomplete="off"/>
                    <label class="btn btn-outline-primary mx-1" for="btn-check-tue">T</label><br></br>

                    <input type="checkbox" className="btn-check" id="btn-check-wed" autocomplete="off"/>
                    <label class="btn btn-outline-primary mx-1" for="btn-check-wed">W</label><br></br>

                    <input type="checkbox" className="btn-check" id="btn-check-thu" autocomplete="off"/>
                    <label class="btn btn-outline-primary mx-1" for="btn-check-thu">T</label><br></br>

                    <input type="checkbox" className="btn-check" id="btn-check-fri" autocomplete="off"/>
                    <label class="btn btn-outline-primary mx-1" for="btn-check-fri">F</label><br></br>

                    <input type="checkbox" className="btn-check" id="btn-check-sat" autocomplete="off"/>
                    <label class="btn btn-outline-primary mx-1" for="btn-check-sat">S</label><br></br>
                </div>
            </div>

            <div className='row'>
                <label htmlFor='importance' className='px-1 pt-2' style={{textAlign: 'left'}}>Importance</label>
                <div className='col-4'>
                  <input type="range" className="form-range" min="1" max="10" id="importance" name="importance" value={taskImportance} onChange={ev => setTaskImportance(ev.target.value)}></input>
                </div>
            </div>

            <div className='row'>
                <label htmlFor='effort' className='px-1 pt-2' style={{textAlign: 'left'}}>Effort</label>
                <div className='col-4'>
                  <input type="range" className="form-range" min="1" max="10" id="effort" name="effort" value={taskEffort} onChange={ev => setTaskEffort(ev.target.value)}></input>
                </div>
            </div>

            <div className='row'>
              <label className='errorMessage'> {taskError} </label>
            </div>

            <Button className='mt-4' onClick={onPlayerCreation}>Create player</Button>
        </form>

    </div>
  )
}