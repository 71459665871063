import React, { useState } from "react"
import PlayersList from "../Players/PlayersList";
import { TaskToday } from "../Task/TaskToday";

export default function Home(props) {
    const { loggedIn, userName, player, setPlayer} = props

    const setChosenPlayer = (playerId, playerName, profilePict) => {
        setPlayer({'id': playerId, 'name': playerName, 'profilePict': profilePict});
    }


    


   return (
   <div className="mainContainer">
        <div className={"titleContainer"}>
            <div><h2>Welcome to Gamefy Life!</h2></div>
        </div>
        <div className="container-fluid">
            {loggedIn ? 
            <div>
                {player.name  ?  
                <div>
                    <h2>Welcome '{player.name}'</h2>
                    <TaskToday player={player}/>
                </div>
                : 
                <div>
                    <h2>Who's playing?</h2>
                    <p>Choose the player for this session:</p>

                    <div className="container-sm">
                        <div className="row justify-content-md-center">
                            <div className="col-lg-9">
                                <PlayersList onPlayerSelection={setChosenPlayer}/>
                            </div>
                        </div>
                    </div>
                </div>
                }
            </div>
            : 
            <div>Not Authenticated.</div>
            }
        </div>
    </div>
    )
}