import { Button, Card, CardBody, CardHeader } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { request } from '../../helpers/axios_helper';
import PlayersList from './PlayersList';

export default function PlayersManage() {
    const navigate = useNavigate();
  
    const onCLick = () => {
      navigate("/players/new");
    }
  
    const editPLayer = (playerId) => {
      console.log(`playerId: ${playerId}`);
    }
  
    return (
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content rounded-4 shadow">
          <div className="modal-body p-5">
  
  
            <h2 className="fw-bold mb-0">Players</h2>
            <PlayersList/>
            <div className='pt-4'>
              <Button onClick={onCLick}>Add player</Button>
            </div>
            </div>
        </div>
      </div>
    )
}