import logo from './logo.svg';
import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { request, getUser } from './helpers/axios_helper';
import { useState, useEffect } from "react";

import Header from './components/Header/Header';
import Home from './components/Home/Home';
import LoginForm from './components/LoginForm/LoginForm';
import AccountForm from './components/AccountForm/AccountForm';
import PlayerForm from './components/Players/PlayerForm';
import NotFound from './NotFound';
import PlayersManage from './components/Players/PlayersManage';
import { TaskForm } from './components/Task/TaskForm';

function App() {


  const [loggedIn, setLoggedIn] = useState(false);
  const [player, setPlayer] = useState({});
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    // Fetch the user email and token from local storage
    const user = getUser();

    // If the token/email does not exist, mark the user as logged out
    if (!user || !user.token) {
      setLoggedIn(false)
      setEmail('');
      setName('');
      // setUser(null);
      return
    }

    // If the token exists, verify it with the auth server to see if it is valid
    request (
      "POST", "/auth/verify", {}
    ).then((response) => {
        setLoggedIn('success' === response.data)
        setEmail(user.email || "")
        setName(user.name);
    }).catch((error) => {
        console.log("Response error: " +  error);
    });
  });

  return (
    <div className='App'>
      <div className="App">
        <BrowserRouter>
          <Header pageTitle="Gamefy Life" logoSrc={logo} loggedIn={loggedIn} player={player}/>

          <Routes>
            <Route path="/" element={<Home loggedIn={loggedIn} userName={name} player={player} setPlayer={setPlayer}/>} />
            <Route path="/login" element={<LoginForm setLoggedIn={setLoggedIn} setEmail={setEmail} setName={setName}/>} />
            <Route path="/register" element={<AccountForm setLoggedIn={setLoggedIn} setEmail={setEmail}/>} />
            <Route path="/players">
              <Route index element={<PlayersManage/>} />
              <Route path=":id" element={<PlayerForm loggedIn={loggedIn}/>} />
            </Route>
            <Route path="/tasks" element={<TaskForm/>} />
            <Route path="*" element={<NotFound/>} />
          </Routes>
        </BrowserRouter>
      </div>
    </div> 
    );
}

export default App;