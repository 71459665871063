import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import { request } from '../../helpers/axios_helper';
import ProfilePictureGalery from '../ProfilePictureGalery/ProfilePictureGalery';

export default function PlayerForm(props) {
  // const playerId = props.playerId;
  const [playerName, setPlayerName] = useState('');
  const [playerGender, setPlayerGender] = useState('');
  const [playerDob, setPlayerDob] = useState('');
  const [profilePict, setProfilePict] = useState('');

  const [playerError, setPlayerError] = useState('');
  
  const  { id } = useParams()

  const navigate = useNavigate();
  const loggedIn = props.loggedIn;

  useEffect(() => {
    if (!loggedIn) navigate("/");
  })

  const onPlayerCreation = () => {
    request (
        "POST",
        "/api/v1/players",
        {
            name: playerName, 
            dob: playerDob,
            gender: playerGender,
            profilePict: profilePict
        }
    ).then((response) => {
        if (response.status == 201) {
          navigate("/players");      
        }
    }).catch((error) => {
      if (error.response.status === 401) {
        setUser(null);
        navigate('/');
      }
    });
  }



  return (

    <div className="modal modal-sheet position-static d-block bg-body p-4 py-md-5" tabIndex="-1" role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content rounded-4 shadow">
        <div className="modal-body p-5">


        <form>
            <div className='row'>
              <h2 className='text-center'>New Player {id}</h2>
              <div className='row'>
                <label htmlFor='name' className='px-1 pt-2' style={{textAlign: 'left'}}>Name</label>
                <input type='text' className='form-control rounded-3' id="name" name="name" value={playerName} placeholder='Type a name' onChange={ev => setPlayerName(ev.target.value)}/>
              </div>
            </div>

            <div className='row'>
              <label htmlFor='dob' className='px-1 pt-2' style={{textAlign: 'left'}}>Date of birth</label>
              <input type='text' className='form-control rounded-3' id="dob" name="dob" value={playerDob} placeholder='Select the date of birth' onChange={ev => setPlayerDob(ev.target.value)}/>
            </div>

            <div className='row'>
              <label htmlFor='gender' className='px-1 pt-2' style={{textAlign: 'left'}}>Gender</label>
              <input type='text' className='form-control rounded-3' id="gender" name="gender" value={playerGender} placeholder='Select the gender' onChange={ev => setPlayerGender(ev.target.value)}/>
            </div>

            <div className='row'>
                <label htmlFor='selectedPicture' className='px-1 pt-2' style={{textAlign: 'left'}}>Picture:</label>
                {profilePict && 
                  <img name="selectedPicture" className='rounded img-thumbnail col-3' key={profilePict} src={require(`../../assets/profile_pictures/${profilePict}`)}  onClick={() => selectImage({image})}/>
                }
            </div>


            <div className='row'>
              <label className='errorMessage'> {playerError} </label>
            </div>

            <Button className='mt-4' onClick={onPlayerCreation}>Create player</Button>
        </form>


        </div>
      </div>
    </div>
    <ProfilePictureGalery setProfilePict={setProfilePict}/>
  </div>
    
  )
}