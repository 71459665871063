import axios from "axios";

export const getUser = () => {
    return JSON.parse(window.localStorage.getItem('user'));
}

export const setUser = (user) => {
    window.localStorage.setItem('user', JSON.stringify(user));
}

export const getStoredPlayer = () => {
    const player = window.localStorage.getItem('player');
    return player ? player : {};
}

export const setStoredPlayer = (playerId, playerName, playerProfileImg) => {
    return window.localStorage.setItem('player', {'id': playerId, 'name': playerName, 'img': playerProfileImg});
}


axios.defaults.baseURL = 'http://localhost:8080';
axios.defaults.headers.post['Content-Type'] = 'application/json';

export const request = (method, url, data) => {
    let headers = {};
    if (getUser() !== null && getUser() !== "null") {
        // headers = {'Authorization': `Bearer ${getAuthToken()}`};
        headers = {'Authorization': `Bearer ${getUser().token}`};
    }
    return axios({
        method: method,
        url: url,
        headers: headers,
        data: data});
};