import { Button, Card, CardBody, CardHeader } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { request } from '../../helpers/axios_helper';

export default function PlayersList(props) {
  const onPlayerSelection = props.onPlayerSelection;
  const navigate = useNavigate();
  
  const getPlayers = () => {
    request (
      "GET",
      "/api/v1/players",
      {}
    ).then((response) => {
      setPlayers(response.data);
    }).catch((error) => {
      if (error.response.status === 401) {
        navigate('/');
      }
    });
  }

  const [players, setPlayers] = useState(() => getPlayers());
  
  return (
    <div className='row body-primary'>
      {players && players.map(player => 
        <div className='col-sm-3' key={JSON.stringify(player.id)} id={JSON.stringify(player.id)}>
        
        <a href="#" onClick={() => onPlayerSelection(player.id, player.name, player.profilePict)}>
        
          <Card className='mt-4'>
            <CardHeader className='fw-semibold'>
              <div className='row'>
                {player.profilePict && 
                <img src={require(`../../assets/profile_pictures/${player.profilePict}`)} className='rounded-circle px-4' width='70px'/>
              }
              </div>
              <div className='row'>
                <p className='fs-3'>{player.name}</p>
              </div>
            </CardHeader>
            <CardBody>Pts X</CardBody>
          </Card>

        </a>

        </div>
      )}
    </div>
  )
}
