import React, { useState } from 'react'

const ProfilePictureGalery = (prop) => {
  const [selectedImage, setSelectedImage] = useState();
  const setProfilePict = prop.setProfilePict;

  const images = require.context('../../assets/profile_pictures', false);
  const imageList = images.keys().map((image) => {
    return image.replace('./', '');
  });

  function selectImage(imgName) {
    console.log(` clicked on ${imgName.image}`);
    setProfilePict(imgName.image);
  }

  return (
    <div className="modal-dialog modal-lg" role="document">
      <div className="modal-content rounded-4 shadow">
        <div className="modal-body px-5 py-4">

          <div className='row'>
            <h2 className="fw-bold mb-0">Select player picture</h2>
          </div>
          <div className='row mt-2'>
              {imageList.map((image) => (
                <div className='col-2'>
                  <img className='rounded img-thumbnail' key={image} src={require(`../../assets/profile_pictures/${image}`)}  onClick={() => selectImage({image})}/>
                </div>
              ))}
        </div>

      </div>
    </div>
  </div>

  )
}

export default ProfilePictureGalery