import './Header.css'
import { useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Header = (props) => {
  const loggedIn = props.loggedIn;
  const player = props.player;

  const navigate = useNavigate();

  const onLoginButtonClick = () => {
    navigate("/login");
  }

  const onRegisterButtonClick = () => {
    navigate("/register");
  }

  return (
    <div className="container">
      <header className="d-flex flex-wrap align-items-center justify-content-center justify-content-md-between py-3 mb-4 border-bottom">
        <nav className="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
          <NavLink to="/" className="nav-link px-2">Home</NavLink>
          <NavLink to="/tasks" className="nav-link px-2">Tasks</NavLink>
        </nav>

        {(loggedIn ? 
            <NavDropdown title={player.profilePict && 
              <img src={require(`../../assets/profile_pictures/${player.profilePict}`)} className='rounded-circle px-4' width='100px' alt={player.name}/>} id="basic-nav-dropdown">
              <NavDropdown.Item href="/players">Manage Players</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Account</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/login">Logout</NavDropdown.Item>
            </NavDropdown>
        :
        <div className="col-md-3 text-end">
          <button type="button" className="btn btn-outline-primary me-2" onClick={onLoginButtonClick}>Login</button>
          <button type="button" className="btn btn-primary" onClick={onRegisterButtonClick}>Sign-up</button>
        </div>
        )}
      </header>
    </div>
  )
}

export default Header