import './AccountForm.css'

import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { request, setUser } from '../../helpers/axios_helper';

const AccountForm = (props) => {
  const setLoggedIn = props.setLoggedIn;
  const setUserEmail = props.setEmail;

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [dob, setDob] = useState();
  const [gender, setGender] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [zip, setZip] = useState();
  const [password, setPassword] = useState();

  const [passwordError, setPasswordError] = useState();

  const navigate = useNavigate();

  const isValidPassword = () => {
    const passwordInput = document.getElementById('password');
    const confPasswordInput = document.getElementById('confirm-password');

    if (passwordInput.value == confPasswordInput.value) {
      setPassword(passwordInput.value);
      setPasswordError('');
      return true;
    } else {
      setPasswordError('Password confirmation is not the same as password');
      return false;
    }
  }

  const onAccountSubmit = (e) => {
    e.preventDefault();

    if (!isValidPassword()) return;

    request (
        "POST",
        "/auth/register",
        {
            name: name, 
            email: email,
            dob: dob,
            gender: gender,
            country: country,
            state: state,
            zip: zip,
            password: password
        }
    ).then((response) => {
        if (response.data == "success") {
          setUser(null);
          setLoggedIn(false);
          setUserEmail(null);
          navigate("/");      
        }
    }).catch((error) => {
        setUser(null);
    });

  }

  return (
    <div className='account'>
        <form>
            <h2>Account registration</h2>
            <label htmlFor='name'>Name</label>
            <input type='text' id="name" name="name" value={name} placeholder='Name' onChange={ev => setName(ev.target.value)}/>

            <label htmlFor='email'>E-mail</label>
            <input type='text' id="email" name="email" value={email} placeholder='E-mail' onChange={ev => setEmail(ev.target.value)}/>

            <label htmlFor='dob'>Date of Birth</label>
            <input type='text' id="dob" name="dob" value={dob} placeholder='Date of Birth' onChange={ev => setDob(ev.target.value)}/>

            <label htmlFor='gender'>Gender</label>
            <input type='text' id="gender" name="gender" value={gender} placeholder='Gender' onChange={ev => setGender(ev.target.value)}/>

            <label htmlFor='country'>Country</label>
            <input type='text' id="country" name="country" value={country} placeholder='Country' onChange={ev => setCountry(ev.target.value)}/>

            <label htmlFor='state'>State</label>
            <input type='text' id="state" name="state" value={state} placeholder='State' onChange={ev => setState(ev.target.value)}/>

            <label htmlFor='zip'>Zip code</label>
            <input type='text' id="zip" name="zip" value={zip} placeholder='Zip code' onChange={ev => setZip(ev.target.value)}/>

            <label htmlFor='password'>Password</label>
            <input type='password' id="password" name="password" value={password} placeholder='Password' onChange={ev => isValidPassword()}/>

            <label htmlFor='confirm-password'>Confirm Password</label>
            <input type='password' id="confirm-password" name="confirm-password" placeholder='Confirm Password'  onChange={ev => isValidPassword()}/>

            <label className='errorMessage'> {passwordError} </label>

            <button onClick={onAccountSubmit}>Create account</button>
        </form>
    </div>
  )
}

export default AccountForm