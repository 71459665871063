import { useState, useEffect } from "react";
import { request, setUser } from '../../helpers/axios_helper';
import { useNavigate } from "react-router-dom";

const LoginForm = (props) => {
    const setLoggedIn = props.setLoggedIn;
    const setUserEmail = props.setEmail;
    const setUserName = props.setName;
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(' ');

    const navigate = useNavigate();

    // Log in a user using email and password
    const onLogIn = (e) => {
        e.preventDefault();
        request (
            "POST",
            "/auth/login",
            {
                email: email,
                password: password

            }
        ).then((response) => {
            const user = {name: response.data.userName, email: response.data.userEmail, token: response.data.accessToken};
            setUser(user);
            setUserName(user.name);
            setLoggedIn(true);
            setUserEmail(user.email);
            navigate("/");    
        }).catch((error) => {
            if (error.response.status === 401) {
                setErrorMessage("Invalid email or password!");
            }
            setUser(null);
        });
    }

    const updateOnChange = (ev) => {
        if (ev.target.id === "email") {
            setEmail(ev.target.value);
        } else if (ev.target.id === "password") {
            setPassword(ev.target.value)
        }
        setErrorMessage(' ');

    }

    useEffect(() => {
        setUserEmail('');
        setUserName('');
        setUser(null);
    });

    return (

    <div className="modal modal-sheet position-static d-block bg-body p-4 py-md-5" tabIndex="-1" role="dialog">
        <div className="modal-dialog" role="document">
            <div className="modal-content rounded-4 shadow">
            <div className="modal-header p-5 pb-4 border-bottom-0">
                <h1 className="fw-bold mb-0 fs-2">Create account</h1>
            </div>

            <div className="modal-body p-5 pt-0">
                <form className="" onSubmit={onLogIn}>
                <div className="form-floating mb-3">
                    <input type="email" className="form-control rounded-3" id="email" placeholder="name@example.com" value={email} onChange={ev => updateOnChange(ev)}/>
                    <label htmlFor="email">Email address</label>
                </div>
                <div className="form-floating mb-3">
                    <input type="password" className="form-control rounded-3" id="password" placeholder="Password" value={password} onChange={ev => updateOnChange(ev)}/>
                    <label htmlFor="password">Password</label>
                </div>
                <div id="errorPanel" style={{color: 'red'}}>
                    {errorMessage}
                </div>

                <button className="w-100 mb-2 btn btn-lg rounded-3 btn-primary" type="submit">Login</button>
                <small className="text-body-secondary">By clicking Sign up, you agree to the terms of use.</small>
                <hr className="my-4"/>
                <h2 className="fs-5 fw-bold mb-3">Or use a third-party</h2>
                <button className="w-100 py-2 mb-2 btn btn-outline-secondary rounded-3" type="submit">
                    <svg className="bi me-1" width="16" height="16"></svg>
                    Sign up with Google
                </button>
                {/* <button className="w-100 py-2 mb-2 btn btn-outline-primary rounded-3" type="submit"> */}
                <button className="w-100 py-2 mb-2 btn btn-outline-secondary rounded-3" type="submit">
                    <svg className="bi me-1" width="16" height="16"></svg>
                    Sign up with Facebook
                </button>
                <button className="w-100 py-2 mb-2 btn btn-outline-secondary rounded-3" type="submit">
                    <svg className="bi me-1" width="16" height="16"></svg>
                    Sign up with GitHub
                </button>
                </form>
            </div>
            </div>
        </div>
    </div>
    )
}

export default LoginForm